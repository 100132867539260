import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLoadingComponent } from './content-loading/content-loading.component';
import { CustomMaterialModule } from '../material.module';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { TimestampToStringPipe } from './pipes/timestamp-to-string.pipe';
import { HiddenDirective } from './directives/hidden.directive';
import { DriveTypePipe } from './pipes/drive-type.pipe';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { StringToTimestampPipe } from './pipes/string-to-timestamp.pipe';
import { MinutesToTimePipe } from './pipes/minutes-to-time';
import { DurationPipe, DurationLinePipe } from './pipes/duration-format';
import { PageWrapperComponent } from './page-wrapper/page-wrapper.component';
import { PageContentDirective } from './directives/page-content.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { DividerComponent } from './divider/divider.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { MatInputModule } from '@angular/material';
import { SortVehiclesPipe } from './pipes/sort-vehicles.pipe';
import { IsGroupSelectedPipe } from './pipes/is-group-selected.pipe';
import { VehicleIconPipe } from './pipes/vehicle-icon.pipe';
import { VehicleStatePipe } from './pipes/vehicle-state.pipe';
import { VehicleMapMarkerPipe } from './pipes/vehicle-map-marker.pipe';
import { SpeedStrokeWidthPipe } from './pipes/speed-stroke-width.pipe';
import { SpeedColorPipe } from './pipes/speed-color.pipe';
import { BodyIconPipe } from './pipes/body-icon.pipe';
import { BodyNamePipe } from './pipes/body-name.pipe';
import { IconSnackBarComponent } from './icon-snackbar/icon-snackbar-component';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { FileFormatPipe } from './pipes/format-file.pipe';
import { PeriodTypePipe } from './pipes/period-type.pipe';
import { DriveTypeOverWritePipe } from './pipes/drive-type-overwrite.pipe';
import { DailySumPipe } from './pipes/daily-sum.pipe';
import { OneDigitDecimaNumberDirective } from './directives/one-digit-decima-number';

const DEFAULT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

const components = [
  ContentLoadingComponent,
  ConfirmDialogComponent,
  PageWrapperComponent,
  DividerComponent,
  PasswordInputComponent,
  IconSnackBarComponent
];

const pipes = [
  TimestampToStringPipe,
  DriveTypePipe,
  StringToTimestampPipe,
  MinutesToTimePipe,
  DurationPipe,
  DurationLinePipe,
  SortVehiclesPipe,
  IsGroupSelectedPipe,
  VehicleIconPipe,
  VehicleStatePipe,
  VehicleMapMarkerPipe,
  SpeedStrokeWidthPipe,
  SpeedColorPipe,
  BodyIconPipe,
  BodyNamePipe,
  WeekdayPipe,
  FileFormatPipe,
  PeriodTypePipe,
  DriveTypeOverWritePipe,
  DailySumPipe
];

const directives = [
  HiddenDirective,
  PageContentDirective,
  OneDigitDecimaNumberDirective
];

const modules = [
  PerfectScrollbarModule,
  CustomMaterialModule
];

@NgModule({
  declarations: [
    ...components,
    ...pipes,
    ...directives
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    ...modules
  ],
  exports: [
    ...components,
    ...pipes,
    ...directives,
    ...modules
  ],
  providers: [
    ConfirmDialogService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [
    ConfirmDialogComponent,
    IconSnackBarComponent,
  ]
})
export class UiComponentsModule { }
