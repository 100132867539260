import { Inject, Injectable } from '@angular/core';
import { UserHttpService } from 'src/app/api/user.service';
import { WorkspaceStore } from './workspace.store';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private path = 'assets/images/';

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private apiService: UserHttpService,
    private workspaceStore: WorkspaceStore,
    private translate: TranslateService
  ) {}

  public getWorkspace() {
    this.apiService.getWorkspaceByBaseUrl(encodeURIComponent(location.origin)).subscribe(
      (response) => {
        this.workspaceStore.update({
          id: response.id,
          name: response.name,
          contactEmail: response.contactEmail,
          contactPhone: response.contactPhone,
          contactCompanyName: response.contactCompanyName,
          contactCompanyStreet: response.contactCompanyStreet,
          contactCompanyCity: response.contactCompanyCity,
          contactCompanyUrl: response.companyURL,
          logoPath: this.getLogoPath(response.id),
          smallLogoPath: this.getSmallLogoPath(response.id),
          faviconPath: this.getFaviconPath(response.id),
          loginBackgroundpath: this.getLoginBackgroundPath(response.id)
        });
        this.setTitle();
        this.setFavicon();
        this.setAppleTouchIcon();
      },
      (error) => {}
    );
  }

  getLogoPath(id: number): string {
    return `${this.path}${!!id ? 'workspace/' + id + '/logo.png' : ''}`;
  }

  getLoginBackgroundPath(id: number): string {
    return !!id ? './../../../assets/images/workspace/' + id + '/background-login.jpg' : '';
  }

  getSmallLogoPath(id: number): string {
    return `${this.path}${!!id ? 'workspace/' + id + '/logo_small.png' : ''}`;
  }

  getFaviconPath(id: number): string {
    return `${this.path}${!!id ? 'workspace/' + id + '/favicon.ico' : ''}`;
  }

  setTitle() {
    let title: string = this.translate.instant('workspace.title.' + this.workspaceStore.getValue().id);
    this.document.getElementById('title').innerHTML = title;
  }

  setFavicon() {
    this.document.getElementById('favicon').setAttribute('href', this.workspaceStore.getValue().faviconPath);
  }

  setAppleTouchIcon() {
    this.document.getElementById('apple-touch-icon').setAttribute('href', './' + this.workspaceStore.getValue().smallLogoPath);
  }
}
