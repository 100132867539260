import { Component, OnInit, OnDestroy} from '@angular/core';
import { IDriveBookDayDTO, IDriveBookPathWrapperDTO } from 'src/app/api/models/dto/drive-books.dto';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { DriveBookQuery } from 'src/app/store/drive-book/state/drive-book.query';
import { Subscription } from 'rxjs';
import { Label, Color } from 'ng2-charts';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MapQuery } from 'src/app/store/map/state/map.query';
import { MapService } from 'src/app/store/map/state/map.service';
import { IMapPoint } from 'src/app/store/map/state/map.store';

@Component({
  selector: 'app-speed-chart',
  templateUrl: './speed-chart.component.html',
  styleUrls: ['./speed-chart.component.scss']
})
export class SpeedChartComponent implements OnInit, OnDestroy {

  public driveBooks: IDriveBookDayDTO[];
  public selectedDriveBookIDs: number[];

  public selectedDriveBookID: number;

  public panelOpenState = false;

  public subscription = new Subscription();

  public carSpeedLabel = 'Car speed';
  public limitSpeedLabel = 'Speed limit';

  public driveBookPaths: IDriveBookPathWrapperDTO[] = [];

  public lineChartData: ChartDataSets[] = [
    { data: [], label: this.carSpeedLabel, yAxisID: 'y-axis' },
    { data: [], label: this.limitSpeedLabel, yAxisID: 'y-axis' }
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    elements: { point: { radius: 0 } },
    scales: {
      xAxes: [{ display: false }],
      yAxes: [
        {
          id: 'y-axis',
          scaleLabel: {
            labelString: 'km/h',
            display: true,
          }
        },
      ]
    },
    tooltips: {
      intersect: false,
      callbacks: {
        label: (tooltipItems, data) => {
          this.showPointOnMap(tooltipItems.xLabel);
          return tooltipItems.yLabel + ' km/h';
        }
      }
    },
    annotation: {
      annotations: [],
    },
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  constructor(
    public readonly driveBookQuery: DriveBookQuery,
    public readonly mapQuery: MapQuery,
    private mapService: MapService,
    private translateService: TranslateService) {
    this.subscription.add(
      this.driveBookQuery.selectedDriveBookIDs$.pipe(
      ).subscribe(ids => this.processSelectedIds(ids))
    );
    this.subscription.add(
      this.driveBookQuery.driveBooks$.pipe(
      ).subscribe(drivebooks => this.driveBooks = drivebooks)
    );
    this.subscription.add(
      this.driveBookQuery.selectedDriveBookPaths$.pipe(
      ).subscribe(driveBookPaths => this.processSelectedDriveBookPaths(driveBookPaths))
    );

    this.subscription.add(
      this.translateService.onLangChange.pipe(
      ).subscribe(() => {
        this.carSpeedLabel = this.translateService.instant('drive-book.drives.vehicle-speed');
        this.limitSpeedLabel = this.translateService.instant('drive-book.drives.limit-speed');
      })
    );

    this.carSpeedLabel = this.translateService.instant('drive-book.drives.vehicle-speed');
    this.limitSpeedLabel = this.translateService.instant('drive-book.drives.limit-speed');
  }

  ngOnInit() {
  }

  chartHovered($event): void {

  }

  chartClicked($event): void {

  }

  showPointOnMap(xValue: any): void {
    const format = 'DD.MM. HH:mm';
    const date = moment(xValue, format);
    const path = Array.prototype.concat.apply([], this.driveBookPaths.map(p => p.paths));
    const point = path.find(x => moment.utc(x.date).local().format(format) === xValue);
    const speedMapPoint: IMapPoint = {
      latitude: point.latitude,
      longitude: point.longitude,
    };
    this.mapService.setSpeedPoint(speedMapPoint);
  }

  removeSpeedPoint(): void {
    this.mapService.setSpeedPoint(null);
  }

  getRouteName(id: number): string {
    const driveBookDay = this.driveBooks.find(x =>
      x.driveBooks.driveBooks.find(d => d.driveBookId === id) !== undefined);
    const driveBook = driveBookDay.driveBooks.driveBooks.find(x => x.driveBookId === id);
    if (driveBook !== undefined) {
      return `${driveBook.startDescription} - ${driveBook.stopDescription} (${driveBook.traceDistance.toFixed(1)} km, ${driveBook.speeding.toFixed(1)} km/h, max ${driveBook.maxSpeed.toFixed(1)} km/h, ${this.transformTime(driveBook.totalTime)} )`;
    }
    return id.toString();
  }

  public transformTime(value: number): string {
    const hours = Math.floor(value / 60).toFixed(0).toString();
    const minutes = (value % 60).toFixed(0).toString();
    return `${ hours.padStart(2, '0') }:${ minutes.padStart(2, '0') }`;
  }

  processSelectedIds(ids: any[]): void {
    this.selectedDriveBookIDs = ids;
    this.selectedDriveBookID = ids[ids.length - 1];
  }
  processSelectedDriveBookPaths(driveBookPaths: IDriveBookPathWrapperDTO[]): void {
    this.driveBookPaths = driveBookPaths;
    const path = driveBookPaths.find(p => p.drivebookId === this.selectedDriveBookID);
    if (path) {
      this.lineChartLabels = path.paths.map(p => moment.utc(p.date).local().format('DD.MM. HH:mm'));
      this.lineChartData = [
        { data: path.paths.map(p => p.speed), label: this.carSpeedLabel, yAxisID: 'y-axis' },
      ];
    } else {
      this.panelOpenState = false;
      this.lineChartData = [
        { data: [], label: this.carSpeedLabel, yAxisID: 'y-axis' },
      ];
    }
  }

  onChangeSource(e: Event): void {
    this.processSelectedDriveBookPaths(this.driveBookPaths);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
