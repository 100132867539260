import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../../store/navigation/state/navigation.service';
import { DriveBookService } from '../../../store/drive-book/state/drive-book.service';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DriveBookQuery } from '../../../store/drive-book/state/drive-book.query';
import { MapService } from '../../../store/map/state/map.service';
import { ActiveMenuItem } from '../../../store/navigation/state/navigation.store';
import { CarsQuery } from '../../../store/cars/state/cars.query';
import { CarsService } from '../../../store/cars/state/cars.service';
import { Router } from '@angular/router';
import { UrlRoutes } from 'src/app/app-routes';

const NAV_PATH = '/dashboard/drive-book';
const PAGE_TITLE = 'page-title.drive-book';

@Component({
  selector: 'app-drive-book-page',
  templateUrl: './drive-book-page.component.html',
  styleUrls: ['./drive-book-page.component.scss']
})
export class DriveBookPageComponent implements OnInit, OnDestroy {

  private subs = new Subscription();

  public isLoadingData: boolean;

  public vehicleId: any;

  public constructor(
    private navigationService: NavigationService,
    private driveBookService: DriveBookService,
    private routerQuery: RouterQuery,
    private router: Router,
    private mapState: MapService,
    private carsState: CarsService,
    public readonly driveBookQuery: DriveBookQuery,
    public readonly carsQuery: CarsQuery
  ) { }

  public ngOnInit(): void {
    this.navigationService.initPage({
      activeMenuItem: ActiveMenuItem.DriveBook,
      pageTitle: PAGE_TITLE,
      panelWidth: 800
    });

    this.navigationService.showContentPanel(NAV_PATH);
    this.carsState.fetchGroupsWithCars();
    this.subs.add(
      this.routerQuery.selectParams().pipe(
        distinctUntilChanged(),
        filter((params) => !!params.car_id),
        map((params) => parseInt(params.car_id as any, 10)),
        distinctUntilChanged()
      ).subscribe((carID) => {
        if (!!this.vehicleId) {
          this.driveBookService.removeAllDrivebooks();
          this.driveBookService.removeTotals();
        }
        this.vehicleId = carID;
        this.mapState.clearPointsExceptOne(this.vehicleId);
        this.carsState.clearIdExceptOne(this.vehicleId);
        return this.driveBookService.selectCar(carID);
      })
    );

    this.subs.add(
      this.driveBookQuery.isLoadingData$.subscribe((isLoading) => this.isLoadingData = isLoading)
    );
  }

  public ngOnDestroy(): void {
    this.navigationService.destroyPage();
    this.subs.unsubscribe();
    this.mapState.clearLines();
    this.driveBookService.removeAllPaths();
  }

  public async changeCar(carID: number): Promise<void> {
    await this.router.navigateByUrl(`${NAV_PATH}/${carID}`);
  }

  public async addDrivebook() {
    await this.router.navigateByUrl(UrlRoutes.urls.drivebook.add);
  }

  public handleExportClick(){
    const path = "/dashboard/export";
    this.navigationService.navigate(path, 'export', () => {
      this.router.navigateByUrl(path);
    });
  }
}
